import { BrowserModule } from '@angular/platform-browser';
import { NgModule, SecurityContext } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  MarkdownModule as NgxMarkdownModule,
  MarkedOptions,
} from 'ngx-markdown';
import { markedOptionsFactory } from './core/markdown.renderer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {
  AngularFireFunctionsModule,
  FunctionsRegionToken,
} from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { environment } from '../../old/environments/environment';
import { ScullyLibModule } from '@scullyio/ng-lib';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFirePerformanceModule,
    NgxMarkdownModule.forRoot({
      sanitize: SecurityContext.NONE, // Required for dynamic links...
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    BrowserAnimationsModule,
    ScullyLibModule,
  ],
  providers: [
    CookieService,
    {
      provide: FunctionsRegionToken,
      useValue: 'europe-west1',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
