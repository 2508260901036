import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MAILCHIMP_TAGS } from 'apps/website/src/environments/environment.common';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { subscriptions, IMetadata, IList, invoices } from 'stripe';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  constructor(
    private afFunctions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
  ) {
    // this.afFunctions.functions.useFunctionsEmulator('http://localhost:5001');
  }

  public hashId() {
    const fn = this.afFunctions.httpsCallable<string, string>('hashId');
    return this.afAuth.authState.pipe(
      // tap(user => console.log('hashId()', user)),
      switchMap((user) => (user ? fn(user.uid) : of(''))),
    );
  }

  public createInvitation(inviter: string) {
    const fn = this.afFunctions.httpsCallable<string, boolean>(
      'createInvitation',
    );
    return fn(inviter).toPromise();
  }

  public addNewsletterSubscription(data: {
    email: string;
    firstName: string;
    lastName?: string;
    company?: string;
    locale: string;
    tags?: string[];
  }) {
    const fn = this.afFunctions.httpsCallable<{}, void>(
      'addNewsletterSubscription',
    );

    if (!data.tags) {
      data.tags = [MAILCHIMP_TAGS.B2C];
    }

    return fn(data).toPromise();
  }

  public deleteNewsletterSubscriber(data: { email: string }) {
    const fn = this.afFunctions.httpsCallable<{}, void>('deleteSubscriber');
    return fn(data).toPromise();
  }

  public createSubscription(data: {
    source?: string;
    payment_method?: string;
    plan: string;
    quantity: number;
    metadata?: IMetadata;
    trial?: number;
  }): Promise<subscriptions.ISubscription> {
    const fn = this.afFunctions.httpsCallable<{}, subscriptions.ISubscription>(
      'createSubscription',
    );
    return fn(data).toPromise();
  }

  public cancelSubscription(
    subsriptionId: string,
  ): Promise<subscriptions.ISubscription> {
    const fn = this.afFunctions.httpsCallable<
      string,
      subscriptions.ISubscription
    >('cancelSubscription');
    return fn(subsriptionId).toPromise();
  }

  public createSetupIntent() {
    const fn = this.afFunctions.httpsCallable<{}, void>('createSetupIntent');
    return fn({}).toPromise();
  }

  public setDefaultPaymentMethod(paymentMethod: string) {
    const fn = this.afFunctions.httpsCallable<{}, void>(
      'setDefaultPaymentMethod',
    );
    return fn({ payment_method: paymentMethod }).toPromise();
  }

  public updateSource(source: string) {
    const fn = this.afFunctions.httpsCallable<{}, void>('updatePaymentMethod');
    return fn({ source }).toPromise();
  }

  public updateCustomerEmail(email: string) {
    const fn = this.afFunctions.httpsCallable<{}, void>('updateCustomer');
    return fn({ email }).toPromise();
  }

  public deleteCustomer(email: string) {
    const fn = this.afFunctions.httpsCallable<{}, void>('deleteCustomer');
    return fn({ email }).toPromise();
  }

  public getLastCancelledSubscription(email: string) {
    const fn = this.afFunctions.httpsCallable<
      {},
      IList<subscriptions.ISubscription>
    >('getLastCancelledSubscription');
    return fn({ email }).toPromise();
  }

  public submitContactForm(data: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    company: string;
    title: string;
    message: string;
    locale: string;
    receiverEmail: string;
  }) {
    const fn = this.afFunctions.httpsCallable<{}, void>('submitContactForm');
    return fn(data).toPromise();
  }

  public sendThanksForBusinessInfo(data: {
    email: string;
    firstName: string;
    locale: string;
  }) {
    const fn = this.afFunctions.httpsCallable<{}, void>(
      'sendMailchimpThanksForJoiningEmail',
    );
    return fn(data).toPromise();
  }

  public getUpcomingInvoice(): Promise<invoices.IInvoice> {
    const fn = this.afFunctions.httpsCallable<
      void,
      invoices.IInvoice | undefined
    >('getUpcomingInvoice');
    return fn().toPromise();
  }
}
