import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(disableDynamic?: boolean): MarkedOptions {
  const renderer = new MarkedRenderer();
  renderer.paragraph = (paragraph) => {
    if (paragraph.startsWith('<a')) {
      return paragraph;
    }
    return `<p>${paragraph}</p>`;
  };
  /* Sanitize some html */
  renderer.html = (html) => {
    if (html.includes('script')) {
      console.warn('Warning from html!');
      return '';
    }
    return html;
  };
  /* Set links as dynamic */
  renderer.link = (href, title, text) => {
    if (text.includes('<img')) {
      return `<a href="${
        href || ''
      }" target="_blank" class="not-button">${text}</a>`;
    }

    return `<a dynamicLink="${href || ''}">${text}</a>`;
  };

  return {
    renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}
