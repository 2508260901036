export const environment = {
  host: 'https://compensate-foundation-testing.appspot.com',
  production: true,
  hmr: false,
  contentful: {
    space: '97nxevqgbpm9',
    accessToken:
      '8c174389fafb96b12a6f661d2d810e191358f21649e1297b06b9f50c9e1fc9b1',
    previewToken:
      '3c154b0ec277ed1d90cc3b33075a1dec0aa7f31984113ecbc734aff6338c2ec3',
  },
  firebase: {
    apiKey: 'AIzaSyCk6ocey5bIfPROidkHFSOmh3WGx5Ynr64',
    authDomain: 'compensate-foundation-testing.firebaseapp.com',
    databaseURL: 'https://compensate-foundation-testing.firebaseio.com',
    projectId: 'compensate-foundation-testing',
    storageBucket: 'compensate-foundation-testing.appspot.com',
    messagingSenderId: '751644473545',
    appId: '1:751644473545:web:007ca32ffd39abf2',
  },
  stripe: {
    plans: {
      '100%': {
        key: '100%',
        id: 'plan_Ejv90a145dcLhD',
        pricePerQuantity: 1,
        name: {
          'en-US': 'Compensate',
          fi: 'Compensate',
        },
        description: {
          'en-US':
            'With this sum you will fully compensate your current carbon footprint.',
          fi: 'Tällä summalla kompensoit nykyisen hiilijalanjälkesi.',
        },
      },
      '200%': {
        key: '200%',
        id: 'plan_EtipsGoHPVkeU0',
        pricePerQuantity: 2,
        name: {
          'en-US': 'Compensate + Clean up',
          fi: 'Compensate + Clean up',
        },
        description: {
          'en-US':
            // tslint:disable-next-line: max-line-length
            'With this sum you will fully compensate your current carbon footprint and clean up the carbon dioxide you have previously created with your actions.',
          fi:
            // tslint:disable-next-line: max-line-length
            'Tällä summalla kompensoit nykyisen hiilijalanjälkesi, ja lisäksi siivoat hiilidioksidia, jonka aiempi elämäsi on ehtinyt tuottaa ilmakehään.',
        },
      },
    },
    public_api_key: 'pk_test_jhPR0DTh39SOD3rNoG8XpIy8',
  },
};
