import {
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { paymentIntents } from 'stripe';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

export function isPaymentIntent(
  object: any
): object is paymentIntents.IPaymentIntent {
  return object ? object.object === 'payment_intent' : false;
}

export function switchCollectionValueChanges<T>() {
  return function op(source: Observable<AngularFirestoreCollection<T>>) {
    return source.pipe(switchMap(collectionValueChanges<T>()));
  };
}

export function switchCollectionDocumentValueChanges<T>(id: string) {
  return function op(source: Observable<AngularFirestoreCollection<T>>) {
    return source.pipe(
      map(collection => collection.doc<T>(id)),
      switchMap(documentValueChanges<T>())
    );
  };
}

export function switchDocumentValueChanges<T>() {
  return function op(source: Observable<AngularFirestoreDocument<T>>) {
    return source.pipe(switchMap(documentValueChanges<T>()));
  };
}

function collectionValueChanges<T>() {
  return (collection: AngularFirestoreCollection<T>) =>
    collection.valueChanges().pipe(catchError(_ => of(undefined)));
}

function documentValueChanges<T>() {
  return (document: AngularFirestoreDocument<T>) =>
    document.valueChanges().pipe(catchError(_ => of(undefined)));
}
