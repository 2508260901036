import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of, from } from 'rxjs';
import {
  map,
  switchMap,
  filter,
  first,
  delayWhen,
  shareReplay,
} from 'rxjs/operators';
import { User } from '../../interfaces/user';
import { StoreService } from '../store/store.service';
import { ActivatedRoute } from '@angular/router';
import {
  subscriptions,
  invoices,
  customers,
  paymentMethods,
  IStripeSource,
} from 'stripe';
import { Invitation, SubscriptionState } from './interfaces';
import {
  isPaymentIntent,
  switchDocumentValueChanges,
  switchCollectionDocumentValueChanges,
  switchCollectionValueChanges,
} from './operators';
import { FunctionsService } from '../functions/functions.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private route: ActivatedRoute,
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth,
    private storeService: StoreService,
    private functionsService: FunctionsService,
  ) {}

  public userDocument() {
    return this.afAuth.authState.pipe(
      filter((user): user is firebase.User => !!user),
      map((user) => this.afStore.collection('users').doc<User>(user.uid)),
      shareReplay(1),
    );
  }

  private userSubscriptionsCollection(q?: QueryFn) {
    return this.userDocument().pipe(
      map((doc) =>
        doc.collection<subscriptions.ISubscription>('subscriptions', q),
      ),
    );
  }

  private userInvoicesCollection(q?: QueryFn) {
    return this.userDocument().pipe(
      map((doc) => doc.collection<invoices.IInvoice>('invoices', q)),
    );
  }

  private userCustomersCollection(q?: QueryFn) {
    return this.userDocument().pipe(
      map((doc) => doc.collection<customers.ICustomer>('customers', q)),
    );
  }

  private userPaymentMethodsCollection(q?: QueryFn) {
    return this.userDocument().pipe(
      map((doc) =>
        doc.collection<paymentMethods.IPaymentMethod>('payment_methods', q),
      ),
    );
  }

  private invitationsCollection() {
    return this.userDocument().pipe(
      map((user) =>
        this.afStore.collection<Invitation>('invitations', (ref) =>
          ref.where('inviterId', '==', user.ref.id),
        ),
      ),
    );
  }

  // Public API for /user/{user}

  public user(): Observable<User | undefined> {
    return this.userDocument().pipe(switchDocumentValueChanges());
  }

  public userDocumentDefines(check: Array<string>): Observable<boolean> {
    return this.userDocument().pipe(
      switchMap((doc) => doc.get().pipe(first())),
      map((snap) => snap.data()),
      filter((snap): snap is User => !!snap),
      map((user) => check.every((prop) => user[prop] !== undefined)),
    );
  }

  public async updateUserDocument(data: Partial<User>): Promise<void> {
    const doc$ = this.userDocument();
    const doc = await doc$.pipe(first()).toPromise();
    return doc.set(data as User, { merge: true });
  }

  public async getUserDocument() {
    const doc$ = this.userDocument();
    return await doc$.pipe(first()).toPromise();
  }

  public async updateUserInfo(data: Partial<User>): Promise<any> {
    const email = data.email;

    /* Email needs to be update to following places:
     * 1. UserDocument
     * 2. Auth (if signed in directly with email & password)
     * 3. Update Email in Stripe
     * 4. Newsletter (if subscriber). TODO.
     */
    const fireauthUser = await this.afAuth.user.pipe(first()).toPromise();
    if (fireauthUser.providerData[0].providerId === 'password') {
      try {
        await fireauthUser.updateEmail(email);
      } catch (e) {
        console.error('Error: ', e);
        return {
          updated: false,
          code: e.code,
          message: e.message,
        };
      }
    }

    try {
      await this.updateUserDocument(data);
    } catch (e) {
      console.error('Error: ', e);
      return {
        updated: false,
        code: e.code,
        message: e.message,
      };
    }

    // Only update for users with Stripe id...
    const userSnapshot = await this.user().pipe(first()).toPromise();
    if (userSnapshot.stripeCustomerId) {
      try {
        const response = await this.functionsService.updateCustomerEmail(email);
      } catch (e) {
        return {
          updated: false,
          code: e.code,
          message: e.message,
        };
      }
    }

    return {
      updated: true,
    };
  }

  public async deleteUserInfo(): Promise<any> {
    const fireauthUser = await this.afAuth.user.pipe(first()).toPromise();

    const userSnapshot = await this.user().pipe(first()).toPromise();

    // delete from newsletter mailing list
    try {
      const mailchimpResponse = await this.functionsService.deleteNewsletterSubscriber(
        { email: userSnapshot.email },
      );
    } catch (e) {
      console.error('Error deleting from Mailchimp: ', e);
      return {
        deleted: false,
        code: e.code,
        message: e.message,
      };
    }

    // delete from Stripe && unsubscribe compensations
    if (userSnapshot.stripeCustomerId) {
      try {
        const response = await this.functionsService.deleteCustomer(
          userSnapshot.email,
        );
      } catch (e) {
        console.error('Error deleting from Stripe: ', e);
        return {
          deleted: false,
          code: e.code,
          message: e.message,
        };
      }
    }

    // delete from Firebase Auth && database
    try {
      await fireauthUser.delete();
    } catch (e) {
      console.error('Error deleting from Firebase Auth: ', e);
      return {
        deleted: false,
        code: e.code,
        message: e.message,
      };
    }

    return {
      deleted: true,
    };
  }

  // Public API for /user/{user}/customers/{customer}

  public userCustomer(): Observable<customers.ICustomer> {
    return this.user().pipe(
      filter((user): user is User => !!user),
      switchMap((user) =>
        this.userCustomersCollection().pipe(
          user.stripeCustomerId
            ? switchCollectionDocumentValueChanges(user.stripeCustomerId)
            : (_) => of(undefined),
          filter((cust): cust is customers.ICustomer => !!cust),
        ),
      ),
    );
  }

  public async getLastCancelledSubscription() {
    const userSnapshot = await this.user().pipe(first()).toPromise();

    const email = userSnapshot.email;
    return this.functionsService.getLastCancelledSubscription(email);
  }

  public updateTestResultFromStore() {
    const testResult = this.storeService.testResult.value;

    if (testResult) {
      // TODO: Check and save only newer testResult
      this.updateUserDocument({
        testResult,
      });
    }
  }

  private customerDefaultSource(customer: customers.ICustomer) {
    if (customer.default_source && customer.sources) {
      const srcId = customer.default_source.toString();
      return customer.sources.data.find((x) => x.id === srcId);
    } else {
      return undefined;
    }
  }

  public defaultPaymentMethod(): Observable<
    paymentMethods.IPaymentMethod | IStripeSource | undefined
  > {
    return this.userCustomer().pipe(
      switchMap((cust) => {
        const settings = cust.invoice_settings;
        if (settings && settings.default_payment_method) {
          return this.userPaymentMethod(settings.default_payment_method);
        } else {
          return of(this.customerDefaultSource(cust));
        }
      }),
    );
  }

  // Public API for /user/{user}/payment_methods/{payment_method}

  public userPaymentMethod(
    pmId: string,
  ): Observable<paymentMethods.IPaymentMethod | undefined> {
    return this.userPaymentMethodsCollection().pipe(
      switchCollectionDocumentValueChanges(pmId),
    );
  }

  // Public API for /user/{user}/subscriptions/{subscription}

  public userSubscription(
    subsId: string,
  ): Observable<subscriptions.ISubscription> {
    return this.userSubscriptionsCollection().pipe(
      switchCollectionDocumentValueChanges(subsId),
      filter((subs): subs is subscriptions.ISubscription => !!subs),
    );
  }

  public userSubscriptions(
    q?: QueryFn,
  ): Observable<subscriptions.ISubscription[] | undefined> {
    return this.userSubscriptionsCollection(q).pipe(
      switchCollectionValueChanges(),
    );
  }

  public userActiveSubscriptions(): Observable<
    subscriptions.ISubscription[] | undefined
  > {
    return this.userSubscriptions((ref) => ref.where('status', '==', 'active'));
  }

  // Public API for /user/{user}/invoices/{invoice}

  public userInvoice(invId: string): Observable<invoices.IInvoice> {
    return this.userInvoicesCollection().pipe(
      switchCollectionDocumentValueChanges(invId),
      filter((subs): subs is invoices.IInvoice => !!subs),
    );
  }

  public userInvoices(
    q?: QueryFn,
  ): Observable<invoices.IInvoice[] | undefined> {
    return this.userInvoicesCollection(q).pipe(switchCollectionValueChanges());
  }

  public userInvoicesSortedByDate(): Observable<
    invoices.IInvoice[] | undefined
  > {
    return this.userInvoices((ref) => ref.orderBy('created', 'desc'));
  }

  public userPaidInvoices(): Observable<invoices.IInvoice[] | undefined> {
    return this.userInvoices((ref) => ref.where('status', '==', 'paid'));
  }

  // Public API for /invitations/{invitee}

  public userInvitations(): Observable<Invitation[] | undefined> {
    return this.invitationsCollection().pipe(switchCollectionValueChanges());
  }

  public catchInvitation() {
    this.route.queryParams
      .pipe(
        filter((params) => params.invite),
        map((params) => params.invite as string),
      )
      .subscribe((invite) => {
        this.storeService.invite.next(invite);
      });
  }

  // Public API for subscription-invoice-paymentIntent

  public subscriptionLifecycle(
    subscriptionId: string,
  ): Observable<SubscriptionState> {
    return this.userSubscription(subscriptionId).pipe(
      switchMap((subscription) => this.subscriptionStates(subscription)),
    );
  }

  private subscriptionStates(subscription: subscriptions.ISubscription) {
    const invoiceId = subscription.latest_invoice.toString();
    return this.userInvoice(invoiceId).pipe(
      switchMap((invoice) => this.invoiceStates(subscription, invoice)),
    );
  }

  private invoiceStates(
    subscription: subscriptions.ISubscription,
    invoice: invoices.IInvoice,
  ) {
    return of(invoice.payment_intent).pipe(
      map((paymentIntent) => ({
        subscription,
        invoice,
        paymentIntent: isPaymentIntent(paymentIntent)
          ? paymentIntent
          : undefined,
      })),
    );
  }
}
