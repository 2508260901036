import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { isScullyRunning } from '@scullyio/ng-lib';
import { ScriptService } from 'apps/website/old/app/services/script/script.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];

  constructor(private scriptService: ScriptService) {}

  ngOnInit(): void {
    const windowResizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.setWindowHeight();
      });

    this.subscriptions.push(windowResizeSubscription);
    this.loadGoogleTagManager();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private loadGoogleTagManager(): void {
    // Don't inject GTM if this was called during the Scully build process,
    // so that scripts are not embedded in the static HTML output.
    if (isScullyRunning()) {
      return;
    }

    if (!isDevMode() && environment.production) {
      this.scriptService
        .load('gtm.production')
        .catch((error) => console.error(error));
    }
  }

  private setWindowHeight(): void {
    window.document.body.style.setProperty(
      '--real-window-height',
      window.innerHeight + 'px',
    );
  }
}
