interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  { name: 'earth', src: '/assets/javascript/earth.min.js' },
  { name: 'stripe', src: 'https://js.stripe.com/v3/' },
  { name: 'gtm.production', src: '/assets/javascript/gtm.production.js' },
  { name: 'gtm.staging', src: '/assets/javascript/gtm.staging.js' },
];
