import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthService } from 'apps/website/old/app/services/auth/auth.service';
import { ProfileService } from 'apps/website/old/app/services/profile/profile.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.currentUser().pipe(
      map((user) => {
        if (!user) {
          this.router.navigate(['/app/login'], {
            queryParams: {
              redirectUrl: route.url ? route.url.join('/') : null,
            },
          });
        }
        return user !== null;
      }),
      mergeMap((loggedIn) => {
        if (!loggedIn) {
          return of(undefined);
        }
        return this.profileService.user();
      }),
      map((user) => {
        if (!user) {
          return false;
        }
        if (user.newsletter === undefined) {
          this.router.navigate(['/app/complete-profile'], {
            queryParams: {
              redirectUrl: route.url ? route.url.join('/') : null,
            },
          });
          return false;
        }
        return true;
      }),
    );
  }
}
@Injectable({
  providedIn: 'root',
})
export class AlreadyAuthenticatedGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.currentUser().pipe(
      map((user) => {
        if (!!user) {
          // If user, can't navigate to wanted page so redirect to profile
          this.router.navigate(['/app/profile']);
          return false;
        }
        return true;
      }),
    );
  }
}
