export const commonEnvironment = {
  localized: false,
};

export const MAILCHIMP_TAGS = {
  B2C: 'B2C',
  B2B: 'B2B',
  WHITEPAPER: 'White paper webinar',
};

export const Locales: Locale[] = [
  {
    title: 'English',
    id: 'en-US',
    slug: 'en',
    default: true,
  },
];

export function getDefaultLocale(): Locale {
  return Locales.find((locale) => locale.default);
}

export function getCurrentLocale(cleanUrl: string): Locale {
  if (!commonEnvironment.localized) {
    return getDefaultLocale();
  }
  const currentLocale = Locales.find((locale) => {
    return RegExp(`^${locale.slug}.*`, 'g').test(cleanUrl);
  });
  if (!currentLocale) {
    // return getDefaultLocale();
  }
  return currentLocale;
}

/* Check also scully.website.config.ts when updating this! */
export const PageTypes: PageType[] = [
  {
    title: 'Page',
    relatedTypeId: 'page',
    slug: null,
    default: true,
  },
  {
    title: 'Article',
    relatedTypeId: 'article',
    template: true,
    rootPage: '/news',
    slug: 'articles',
  },
  {
    title: 'Project',
    relatedTypeId: 'project',
    template: true,
    rootPage: '/sustainability',
    slug: 'projects',
  },
  /* 
  {
    // Not used but created in Contentful!
    title: 'Partner',
    relatedTypeId: 'partner',
    template: true,
    rootPage: '/for-business',
    slug: 'partners',
  },
  */
  {
    title: 'Basic / Priciples',
    relatedTypeId: 'basics',
    template: true,
    rootPage: '/sustainability',
    slug: 'basics',
  },
];

export function getDefaultPageType(): PageType {
  return PageTypes.find((pageType) => pageType.default);
}

export function getCurrentPageType(actualSlug: string): PageType {
  const currentPageType = PageTypes.find(
    (pageType) =>
      pageType.slug && RegExp(`\/?${pageType.slug}\/.+`).test(actualSlug),
  );

  if (!currentPageType) {
    return getDefaultPageType();
  }

  return currentPageType;
}
