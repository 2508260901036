import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private cookieService: CookieService,
  ) {}

  private push(data?: any) {
    if (
      isPlatformBrowser(this.platformId) &&
      this.cookieService.get('consent') == 'true' &&
      (window as any).dataLayer
    ) {
      (window as any).dataLayer.push({
        ...data,
      });
    }
  }

  routeChange(newUrl: string) {
    this.push({
      event: 'routeChange',
      route: {
        routeUrl: newUrl,
      },
    });
  }

  customEvent(data?: any) {
    this.push({
      event: 'customEvent',
      customEvent: {
        category: data.category,
        action: data.action,
        label: data.label || undefined,
        value: data.value || undefined,
        nonInteraction: data.nonInteraction || false,
      },
    });
  }

  customMessage(data: any) {
    this.push(data);
  }

  purchase(data: any) {
    this.push({
      event: 'eec.purchase',
      ...data,
    });
  }
}
