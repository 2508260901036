import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataLayerService } from './dataLayer.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private router: Router, private dataLayer: DataLayerService) {}

  sendFirstPageWithLoginStatus(loginStatus: string) {
    this.dataLayer.customMessage({
      loginStatus
    });
    this.dataLayer.routeChange(this.router.url);
  }

  handleRouteChange() {
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.dataLayer.routeChange(this.router.url);
      }
    });
  }

  nextTestCategory(newCategoryTitle) {
    this.dataLayer.customEvent({
      category: 'Take the test',
      action: 'Start new category',
      label: newCategoryTitle
    });
  }

  firstTestCategory(newCategoryTitle) {
    this.dataLayer.customMessage({ testStartTime: new Date().getTime() });
    this.dataLayer.customEvent({
      category: 'Take the test',
      action: 'Start new category',
      label: newCategoryTitle,
      nonInteraction: true
    });
  }

  finishTest(testResult) {
    this.dataLayer.customMessage({ testEndTime: new Date().getTime() });
    this.dataLayer.customEvent({
      category: 'Take the test',
      action: 'Finish',
      label: testResult
    });
  }

  startInternationalTest(country) {
    this.dataLayer.customMessage({ testStartTime: new Date().getTime() });
    this.dataLayer.customEvent({
      category: 'International test',
      action: 'Start: ' + country
    });
  }

  finishInternationalTest(country, testResult) {
    this.dataLayer.customMessage({ testEndTime: new Date().getTime() });
    this.dataLayer.customEvent({
      category: 'International test',
      action: 'Finish: ' + country,
      label: Math.round(testResult)
    });
  }

  selectPlan(planKey: string, pricePerMonth: number) {
    this.dataLayer.customEvent({
      category: 'Select plan',
      action: planKey,
      value: pricePerMonth * 12
    });
  }

  clickShareButton(category: string, channel: string) {
    this.dataLayer.customEvent({
      category,
      action: channel
    });
  }

  makePurchase(purchaseId: string, planKey: string, totalPrice: string) {
    this.dataLayer.purchase({
      ecommerce: {
        purchase: {
          actionField: {
            id: purchaseId,
            revenue: totalPrice
          },
          products: [
            {
              id: planKey,
              name: planKey,
              quantity: 1,
              price: totalPrice
            }
          ]
        }
      }
    });
  }

  newUserCreated(providerId: string) {
    this.dataLayer.customMessage({
      loginStatus: 'logged-in'
    });
    this.dataLayer.customEvent({
      category: 'User',
      action: 'New user created',
      label: providerId
    });
  }

  signIn(providerId: string) {
    this.dataLayer.customMessage({
      loginStatus: 'logged-in'
    });
    this.dataLayer.customEvent({
      category: 'User',
      action: 'Sign in',
      label: providerId || 'n/a'
    });
  }

  signOut() {
    this.dataLayer.customMessage({
      loginStatus: 'logged-out'
    });
    this.dataLayer.customEvent({
      category: 'User',
      action: 'Sign out'
    });
  }
}
