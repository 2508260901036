import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FunctionsService } from '../functions/functions.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { ProfileService } from '../profile/profile.service';
import { StoreService } from '../store/store.service';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authError$ = new BehaviorSubject<firebase.auth.Error | null>(null);
  private authSsoError$ = new BehaviorSubject<firebase.auth.Error | null>(null);

  constructor(
    private afAuth: AngularFireAuth,
    private fnService: FunctionsService,
    private analyticsService: AnalyticsService,
    private profileService: ProfileService,
    private store: StoreService,
  ) {}

  public authError() {
    return this.authError$;
  }

  public authSsoError() {
    return this.authSsoError$;
  }

  public resetAuthError() {
    this.authError$.next(null);
  }

  public resetAuthSsoError() {
    this.authSsoError$.next(null);
  }

  public currentUser() {
    return this.afAuth.authState;
  }

  public hasPassword() {
    return this.afAuth.authState.pipe(
      map(
        (user) =>
          !!user &&
          user.providerData.some(
            (provider) => !!provider && provider.providerId === 'password',
          ),
      ),
    );
  }

  public isLoggedIn() {
    return this.afAuth.authState.pipe(map((user) => !!user));
  }

  public async signInAnonymously() {
    const cred = await this.afAuth.auth
      .signInAnonymously()
      .catch((error) => this.authError$.next(error));
    return this.afterSignIn(cred || null);
  }

  public async createUserWithEmailAndPassword(email: string, password: string) {
    const cred = await this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .catch((error) => this.authError$.next(error));
    return this.afterSignIn(cred || null);
  }

  public async signInWithEmailAndPassword(email: string, password: string) {
    const cred = await this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => this.authError$.next(error));
    return this.afterSignIn(cred || null);
  }

  public signInWithPopupGoogle() {
    const prov = new auth.GoogleAuthProvider();
    return this.signInWithPopup(prov);
  }

  public signInWithPopupFacebook() {
    const prov = new auth.FacebookAuthProvider();
    return this.signInWithPopup(prov);
  }

  public signInWithPopupTwitter() {
    const prov = new auth.TwitterAuthProvider();
    return this.signInWithPopup(prov);
  }

  public signInWithRedirectGoogle() {
    const prov = new auth.GoogleAuthProvider();
    return this.afAuth.auth.signInWithRedirect(prov);
  }

  public async signInWithPopup(prov: firebase.auth.AuthProvider) {
    const cred = await this.afAuth.auth
      .signInWithPopup(prov)
      .catch((error) => this.authSsoError$.next(error));
    return this.afterSignIn(cred || null);
  }

  public async handleRedirectResult() {
    const cred = await this.afAuth.auth
      .getRedirectResult()
      .catch((error) => this.authError$.next(error));
    return this.afterSignIn(cred || null);
  }

  private afterSignIn(cred: auth.UserCredential | null) {
    if (cred && cred.user) {
      this.authError$.next(null);
      this.savePreSignInData();
      this.profileService.updateTestResultFromStore();
      if (cred.additionalUserInfo && cred.additionalUserInfo.isNewUser) {
        this.analyticsService.newUserCreated(
          cred.additionalUserInfo.providerId,
        );
        this.completeInvitation();
        this.updateUserLocale();
      } else if (cred.additionalUserInfo) {
        this.analyticsService.signIn(cred.additionalUserInfo.providerId);
      }
      return cred;
    }
  }

  private async savePreSignInData() {
    const data = this.store.preSignInData.value;
    if (data !== undefined) {
      await this.profileService.updateUserDocument(data);
      this.store.preSignInData.next(undefined);
    }
  }

  private async completeInvitation() {
    const invite = this.store.invite.value;
    if (invite) {
      await this.fnService.createInvitation(invite);
    }
  }

  private async updateUserLocale() {
    // const userSubscription = this.afAuth.user.subscribe(firebaseUser => {
    //   this.afStore
    //     .collection('users')
    //     .doc(firebaseUser.uid)
    //     .update({ locale: this.contentfulService.locale.value })
    //     .then(doc => {
    //       console.log(doc);
    //       userSubscription.unsubscribe();
    //     })
    //     .catch(error => {
    //       console.error(error);
    //       userSubscription.unsubscribe();
    //     });
    // });
  }

  public checkActionCode(actionCode: string) {
    return this.afAuth.auth.checkActionCode(actionCode);
  }
  public applyActionCode(actionCode: string): Promise<void> {
    return this.afAuth.auth.applyActionCode(actionCode);
  }

  public verifyPasswordResetCode(actionCode: string): Promise<string> {
    return this.afAuth.auth.verifyPasswordResetCode(actionCode);
  }

  public confirmPasswordReset(
    actionCode: string,
    newPassword: string,
  ): Promise<void> {
    return this.afAuth.auth.confirmPasswordReset(actionCode, newPassword);
  }

  public resetPassword(data: { email: string }, lang: string) {
    this.afAuth.auth.languageCode = lang.substr(0, 2);
    return this.afAuth.auth.sendPasswordResetEmail(data.email, {
      url: environment.emailVerificationUrl,
      android: { packageName: environment.packageName },
      iOS: { bundleId: environment.bundleId },
      handleCodeInApp: true,
      dynamicLinkDomain: environment.dynamicLinkDomain,
    });
  }

  public async updatePassword(data: { password: string }) {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    if (!user) {
      throw new Error('User not logged in!');
    }
    return user.updatePassword(data.password);
  }

  public signOut() {
    this.analyticsService.signOut();
    return this.afAuth.auth.signOut();
  }
}
