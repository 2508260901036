import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AlreadyAuthenticatedGuard, AuthGuard } from './core/auth.guard';

const oldRedirectPaths = [
  'forgot-password',
  'consent-revoke',
  'checkout/pay',
  'checkout/thank-you',
  'settings',
  'user-management',
  'forgot-password',
];

const appRedirectPaths = [
  'login',
  'result',
  'test',
  'start-test',
  'profile',
  // 'register',
  'international-test',
  'checkout/login',
  // 'checkout/join',
];

const privacyStatementRedirects = [
  'fi/page/privacy-statement',
  'page/privacy-statement',
  'page/tietosuojaseloste',
];
const newsletterPrivacyStatementRedirects = [
  'fi/page/privacy-statement-newsletter',
  'page/privacy-statement-newsletter',
];
const termsRedirects = [
  'fi/page/terms-and-conditions',
  'fi/page/kayttoehdot',
  'page/terms-and-conditions',
];

const routes: Routes = [
  // Redirects to app
  ...appRedirectPaths.map((path) => {
    const route: Route = {
      path,
      redirectTo: `app/${path}`,
    };
    return route;
  }),

  // Create redirect to old
  ...oldRedirectPaths.map((path) => {
    const route: Route = {
      path,
      redirectTo: `old/${path}`,
    };
    return route;
  }),

  //
  ...privacyStatementRedirects.map((path) => {
    const route: Route = {
      path,
      redirectTo: `privacy-statement`,
    };
    return route;
  }),
  //
  ...newsletterPrivacyStatementRedirects.map((path) => {
    const route: Route = {
      path,
      redirectTo: `newsletter-and-events-privacy-statement`,
    };
    return route;
  }),
  //
  ...termsRedirects.map((path) => {
    const route: Route = {
      path,
      redirectTo: `terms-of-use`,
    };
    return route;
  }),

  // Serve old if not implemented with app route
  ...oldRedirectPaths.map((path) => {
    const route: Route = {
      path: `app/${path}`,
      redirectTo: `old/${path}`,
    };
    return route;
  }),
  {
    path: 'old',
    loadChildren: () =>
      import('../../old/app/old-view.module').then((m) => m.OldViewModule),
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./views/embedded-careers-view/embedded-careers-view.module').then(
        (m) => m.EmbeddedCareersViewModule,
      ),
  },
  {
    path: 'app',
    children: [
      {
        path: 'login',
        canActivate: [AlreadyAuthenticatedGuard],
        loadChildren: () =>
          import('./views/login-view/login-view.module').then(
            (m) => m.LoginViewModule,
          ),
      },
      {
        path: 'checkout/login',
        canActivate: [AlreadyAuthenticatedGuard],
        loadChildren: () =>
          import('./views/login-view/login-view.module').then(
            (m) => m.LoginViewModule,
          ),
      },
      // {
      //   path: 'join',
      //   canActivate: [AlreadyAuthenticatedGuard],
      //   loadChildren: () =>
      //     import('./views/create-account-view/create-account-view.module').then(
      //       (m) => m.CreateAccountViewModule,
      //     ),
      // },
      // {
      //   path: 'register',
      //   canActivate: [AlreadyAuthenticatedGuard],
      //   loadChildren: () =>
      //     import('./views/create-account-view/create-account-view.module').then(
      //       (m) => m.CreateAccountViewModule,
      //     ),
      // },
      // {
      //   path: 'checkout/register',
      //   canActivate: [AlreadyAuthenticatedGuard],
      //   loadChildren: () =>
      //     import('./views/create-account-view/create-account-view.module').then(
      //       (m) => m.CreateAccountViewModule,
      //     ),
      // },
      // {
      //   path: 'checkout/join',
      //   canActivate: [AlreadyAuthenticatedGuard],
      //   loadChildren: () =>
      //     import('./views/create-account-view/create-account-view.module').then(
      //       (m) => m.CreateAccountViewModule,
      //     ),
      // },
      {
        path: 'test',
        loadChildren: () =>
          import('./views/calculator-view/calculator-view.module').then(
            (m) => m.CalculatorViewModule,
          ),
      },
      {
        path: 'results',
        loadChildren: () =>
          import('./views/results-view/results-view.module').then(
            (m) => m.ResultsViewModule,
          ),
      },
      {
        path: 'start-test',
        loadChildren: () =>
          import('./views/start-test-view/start-test-view.module').then(
            (m) => m.StartTestViewModule,
          ),
      },
      {
        path: 'international-test',
        loadChildren: () =>
          import(
            './views/international-test-view/international-test-view.module'
          ).then((m) => m.InternationalTestViewModule),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/user-profile-view/user-profile-view.module').then(
            (m) => m.UserProfileViewModule,
          ),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/user-settings-view/user-settings-view.module').then(
            (m) => m.UserSettingsViewModule,
          ),
      },

      {
        path: 'checkout/pay',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/payment-view/payment-view.module').then(
            (m) => m.PaymentViewModule,
          ),
      },
      {
        path: 'complete-profile',
        loadChildren: () =>
          import('./views/complete-profile/complete-profile.module').then(
            (m) => m.CompleteProfileModule,
          ),
      },
    ],
  },
  // Content pages
  {
    path: '**',
    loadChildren: () =>
      import('./views/dynamic-view/dynamic-view.module').then(
        (m) => m.DynamicViewModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
