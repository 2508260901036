import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, NEVER } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  public testResult = this.createAccessor<{ [key: string]: any }>(
    'testResult',
    undefined
  );
  public selectedPlan = this.createAccessor<{ [key: string]: any }>(
    'selectedPlan',
    undefined
  );
  public selectedPlanQuantity = this.createAccessor<number>(
    'selectedPlanQuantity'
  );
  public selectedPlanCo2e = this.createAccessor<number>('selectedPlanCo2e');
  public selectedPlanTotal = this.createAccessor<number>('selectedPlanTotal');
  public selectedPlanDay = this.createAccessor<number>('selectedPlanDay');
  public invite = this.createAccessor<string>('invite');
  public preSignInData = this.createAccessor<Partial<User>>('preSignInData');
  public country = this.createAccessor<any>('country');
  public returnUrl = this.createAccessor<string>('returnUrl');

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  private createAccessor<T>(localStorageKey?: string, defaultValue?: T) {
    const subject = new BehaviorSubject<T | undefined>(undefined);
    if (localStorageKey && isPlatformBrowser(this.platformId)) {
      this.setPropertyValueFromStorage<T>(localStorageKey, subject);
      this.subscribePropertyAndSaveValueToStorage<T>(localStorageKey, subject);
    }
    if (subject.value === undefined && defaultValue !== undefined) {
      subject.next(defaultValue);
    }
    return subject;
  }

  private subscribePropertyAndSaveValueToStorage<T>(
    key: string,
    property: BehaviorSubject<T | undefined>
  ): void {
    property
      .pipe(
        map(value => JSON.stringify(value)),
        catchError(() => NEVER)
      )
      .subscribe(json => {
        if (json === undefined) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, json);
        }
      });
  }

  private setPropertyValueFromStorage<T>(
    key: string,
    property: BehaviorSubject<T | undefined>
  ): void {
    const json = localStorage.getItem(key);
    if (json !== null) {
      try {
        property.next(JSON.parse(json));
      } catch {}
    }
  }
}
