export const environment: AppEnvironment = {
  production: false,
  host: 'https://staging.compensate.com',
  contentful: {
    space: 'f6kng81cu8b8',
    accessToken: 'GxhiL8KPVuZIGzkH2YVHrZ8FIm_OnUEokrPXjh0UF14',
    environment: 'master',
    host: 'preview.contentful.com',
  },
  globalContentId: '7H3B2MSddDs5IwcPegxtKL',
  calculatorDataId: '2L2GnCjadyoGeZMwfsZWVL',
  oldContentful: {
    space: '97nxevqgbpm9',
    environment: 'master',
    accessToken:
      '8c174389fafb96b12a6f661d2d810e191358f21649e1297b06b9f50c9e1fc9b1',
  },
  plans: [
    {
      key: '100%',
      id: 'plan_Ejv90a145dcLhD',
      pricePerQuantity: 1,
      name: 'Compensate',
      description:
        'With this sum you will fully compensate your current carbon footprint.',
    },
    {
      key: '200%',
      id: 'plan_EtipsGoHPVkeU0',
      pricePerQuantity: 2,
      name: 'Compensate + Clean up',

      description:
        // tslint:disable-next-line: max-line-length
        'With this sum you will fully compensate your current carbon footprint and clean up the carbon dioxide you have previously created with your actions.',
    },
  ],
  emailVerificationUrl:
    'https://compensate-foundation-testing.appspot.com/user-management',
  bundleId: 'com.compensate.mobileapp',
  packageName: 'com.compensate.mobileapp',
  dynamicLinkDomain: 'compensatedev.page.link',
};
